import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { AuthState } from '../models/auth.model';

const initialState: AuthState = {
  user: null,
  accessToken: '',
  refreshToken: '',
};

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(store => ({
    updateAuthenticationState(authState: AuthState): void {
      patchState(store, authState);
    },
    restoreAuthenticationState(): void {
      patchState(store, initialState);
    },
  })),
);
