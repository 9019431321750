import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  setTokenAndRefreshToken({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }): void {
    localStorage.setItem('mcp_access_token', accessToken);
    localStorage.setItem('mcp_refresh_token', refreshToken);
  }

  getTokenAndRefreshToken(): { token: string | null; refreshToken: string | null } {
    return {
      token: localStorage.getItem('mcp_access_token'),
      refreshToken: localStorage.getItem('mcp_refresh_token'),
    };
  }

  deleteTokenAndRefreshToken(): void {
    localStorage.removeItem('mcp_access_token');
    localStorage.removeItem('mcp_refresh_token');
  }
}
